<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.type"
        placeholder="类型"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in NEWS_CENTER_TYPE"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="id" label="编号" width="80"> </el-table-column>

      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="type" label="新闻类型">
        <template slot-scope="scope">
          {{ getValue(NEWS_CENTER_TYPE, scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="sub_title" label="副标题"> </el-table-column>
      <el-table-column prop="default_img" label="封面图">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.default_img"
            style="width: 100px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="att_type" label="附件类型">
        <template slot-scope="scope">
          {{ getValue(ATT_TYPE, scope.row.att_type) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="checkDialogFormVisible(true, scope.row)"
            style="margin-right: 8px"
            >编辑</el-button
          >
          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定要修改新闻状态吗？"
            @confirm="statusChange(scope.row)"
          >
            <el-switch
              :value="scope.row.status === 0"
              slot="reference"
            ></el-switch>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogType]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      @close="checkDialogFormVisible(false, {})"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="副标题" prop="subTitle">
          <el-input v-model="form.subTitle" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="封面图" prop="defImg">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.NEWS"
            @valueFunc="getImgsValueFunc"
            paramsType="defImg"
            :model="form.defImg"
            :maxCount="1"
          />
        </el-form-item>

        <el-form-item label="新闻类型" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择新闻类型"
            style="width:100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in NEWS_CENTER_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="新闻内容" prop="content">
          <quill-editor
            v-model="form.content"
            class="content_warp"
          ></quill-editor>
        </el-form-item>

        <el-form-item label="附件类型" prop="attType">
          <el-select
            v-model="form.attType"
            placeholder="请选择附件类型"
            style="width:100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in ATT_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="附件数据"
          prop="attData"
          v-if="[1, 2].includes(form.attType)"
        >
          <uploadDocument
            :typeProps="UPLOAD_IMG_TYPE.NEWS"
            @valueFunc="getAttDataValueFunc"
            paramsType="attData"
            :model="form.attData"
            v-if="form.attType === 2"
            :maxCount="1"
          />
          <uploadVideo
            :typeProps="UPLOAD_IMG_TYPE.NEWS"
            @valueFunc="getAttDataValueFunc"
            paramsType="attData"
            :model="form.attData"
            v-if="form.attType === 1"
            :maxCount="1"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {})">取消</el-button>
        <el-button
          type="primary"
          @click="saveAddOrEditData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  DIALOG_TITLE_TYPE,
  UPLOAD_IMG_TYPE,
  NEWS_CENTER_TYPE,
  ATT_TYPE,
  getValue
} from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import { getNewsList, createNews, switchStatus } from "@/api/newsCenter";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import UploadList from "@/views/components/uploadList.vue";
import uploadDocument from "@/views/components/uploadDocument.vue";
import uploadVideo from "@/views/components/uploadVideo.vue";
import { getFileName } from "@/utils/utils";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    },
    NEWS_CENTER_TYPE() {
      return NEWS_CENTER_TYPE;
    },
    ATT_TYPE() {
      return ATT_TYPE;
    },
    getValue() {
      return getValue;
    }
  },
  components: {
    UploadList,
    quillEditor,
    uploadDocument,
    uploadVideo
  },
  filters: {},
  data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        type: "",
        page: 1,
        limit: 10
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogType: "",
      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "change" }],
        defImg: [
          { required: true, message: "封面图不能为空", trigger: "change" }
        ],
        content: [
          { required: true, message: "内容不能为空", trigger: "change" }
        ],
        type: [
          { required: true, message: "新闻类型不能为空", trigger: "change" }
        ],
        attType: [
          { required: true, message: "附件类型不能为空", trigger: "change" }
        ]
      },
      form: {
        id: 0,
        title: "", // 正标题
        subTitle: "", // 副标题
        defImg: [], // 封面图
        content: "", // 新闻内容
        type: "", // 新闻类型
        attType: 0, // 附件类型
        attData: [] // 附件数据
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      getNewsList(this.listQuery)
        .then(response => {
          this.list = (response.data && response.data.data) || [];
          this.total = response.data.total || 0;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      if (editObj && Object.keys(editObj).length !== 0) {
        console.log("editObj", editObj);
        this.form.id = editObj.id;
        this.form.title = editObj.title;
        this.form.subTitle = editObj.sub_title;
        this.form.content = editObj.content;
        this.form.type = editObj.type;
        this.form.attType = editObj.att_type;
        if (editObj.default_img) {
          const defImgArr = [];
          const arr = editObj.default_img.split(",");
          arr.map(item => {
            defImgArr.push({
              url: item,
              name: getFileName(item)
            });
          });
          this.form.defImg = defImgArr;
        }
        if (editObj.att_data) {
          const attDataArr = [];
          const arr = editObj.att_data.split(",");
          arr.map(item => {
            attDataArr.push({
              url: item,
              name: getFileName(item)
            });
          });
          this.form.attData = attDataArr;
        }
      }
      this.dialogFormVisible = status;
      if (!status) {
        this.form.title = "";
        this.form.subTitle = "";
        this.form.defImg = [];
        this.form.content = "";
        this.form.id = 0;
        this.form.type = "";
        this.form.attType = "";
        this.form.attData = [];
        this.$validator.reset();
      }
      console.log("this.form.", this.form);
    },
    /**
     * 确认添加还是编辑
     */
    saveAddOrEditData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;

          let urlArrDefImg = [];
          if (params.defImg && params.defImg.length !== 0) {
            urlArrDefImg = params.defImg.map(obj => obj["url"]);
          }
          params.defImg = urlArrDefImg.join(",");

          let urlArr = [];
          if (params.attData && params.attData.length !== 0) {
            urlArr = params.attData.map(obj => obj["url"]);
          }
          params.attData = urlArr.join(",");

          this.btnLoading = true;
          createNews(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false);
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },

    /**
     * 确认上传
     * @param type
     * @param res
     */
    getImgsValueFunc(type, res) {
      if (type === "defImg") {
        this.form.defImg = res;
      }
    },
    /**
     * 确认上传文档或者音频
     * @param type
     * @param res
     */
    getAttDataValueFunc(type, res) {
      if (type === "attData") {
        this.form.attData = res;
      }
    },
    /**
     * 状态切换
     * @param scope
     */
    statusChange(scope) {
      const params = {
        id: scope.id,
        status: scope.status === 0 ? 1 : 0
      };
      switchStatus(params).then(() => {
        this.getList();
        this.$notify({
          title: "成功",
          message: "状态编辑成功",
          type: "success",
          duration: 2000
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.content_warp {
  /deep/ {
    .ql-container {
      min-height: 400px;
    }
    .ql-editor {
      min-height: 400px;
    }
  }
}
</style>
